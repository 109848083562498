/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Following code is to add support for SAFARI AND InternetEXPLORER

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
// export const onClientEntry = () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (typeof window.IntersectionObserver === `undefined`) {
//       import(`intersection-observer`)
//       console.log(`# IntersectionObserver is polyfilled!`)
//     }
//   }

// Using Gatsby Browser APIs to wrap the root element (where the app is)

import React from 'react';
import GlobalContextProvider from './src/context/GlobalContextProvider';

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    {element}
  </GlobalContextProvider>

);
