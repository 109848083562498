// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-contact-me-tsx": () => import("./../../../src/pages/contact-me.tsx" /* webpackChunkName: "component---src-pages-contact-me-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-work-tsx": () => import("./../../../src/pages/my-work.tsx" /* webpackChunkName: "component---src-pages-my-work-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */)
}

