import React from "react"
import { GlobalState } from "../types/types"
export const GlobalStateContext = React.createContext({})
export const GlobalDispatchContext = React.createContext({})


//Initial STATE
const initialState: GlobalState = {
  projectsList: null,
  personalProjects: true,
  mainPageAnimations: true,
  menuFixedForDesktop: false
}

//A reducer is a function with params of state and action and returns the new state
// which in this case is an object

interface Action {
  type: string
  payload: any
}

function reducer(state: GlobalState, action: Action) {

  switch (action.type) {
    case "SET_PROJECT_LIST": {
      return {
        ...state,
        allProjectsList: action.payload,
        projectsList: action.payload
      }
    }
    case "REMOVE_PERSONAL_PROJECTS": {

      if (state?.projectsList?.wpgraphql?.page?.children?.edges) {

        const allProjects = state.projectsList.wpgraphql.page.children.edges;
        const personalProjects = allProjects.filter(x => x.node.portfolioProject.personalproject == null);

        return {
          ...state,
          projectsList: { wpgraphql: { page: { children: { edges: personalProjects } } } },
          personalProjects: false,
        }
      }

      else return { ...state }
    }

    case "ADD_PERSONAL_PROJECTS": {

      if (state?.projectsList?.wpgraphql?.page?.children?.edges) {
        return {
          ...state,
          projectsList: state.allProjectsList,
          personalProjects: true,
        }
      }

      else return { ...state }
    }

    case "REMOVE_MAIN_PAGE_ANIMATIONS": {

      return {
        ...state,
        mainPageAnimations: false
      }

    }
    case "ADD_MAIN_PAGE_ANIMATIONS": {
      return {
        ...state,
        mainPageAnimations: true
      }

    }
    case "MENU_FIXED_DESKTOP": {
      return {
        ...state,
        menuFixedForDesktop: true
      }
    }

    case "MENU_RELATIVE_DESKTOP": {
      return {
        ...state,
        menuFixedForDesktop: false
      }
    }

    default:
      throw new Error("Bad Action Type")
  }
}


//This acts like a wrapper component (return children)
const GlobalContextProvider = ({ children }) => {
  //use Reducer hook takes (reducer, initialState)
  const [state, dispatch] = React.useReducer(reducer, initialState)
  // console.log('STATE:',state);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
